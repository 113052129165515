import { gql } from '@apollo/client';
import {
  USER_FRAGMENT,
  CLIENT_FRAGMENT,
  ORDER_FRAGMENT,
  PAYMENT_FRAGMENT,
  APPOINTMENT_FRAGMENT,
  APPOINTMENT_ORDER_FRAGMENT,
  SHOP_CART_FRAGMENT,
} from './fragments';

export const SIGN_IN = gql`
  mutation SIGN_IN($data: SignInInput!) {
    signIn(data: $data) {
      user {
        ...UserFragment
        loginToken
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const SIGN_OUT = gql`
  mutation SIGN_OUT {
    signOut {
      success
    }
  }
`;

export const SIGN_UP = gql`
  mutation SIGN_UP($data: SignUpInput!) {
    signUp(data: $data) {
      token
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const SIGN_S3 = gql`
  mutation SIGN_S3($data: SignS3Input!) {
    signS3(data: $data) {
      signedRequest
      url
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation CREATE_ORDER($data: CreateOrderInput!) {
    createOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const UPDATE_ORDER = gql`
  mutation UPDATE_ORDER($data: UpdateOrderInput!) {
    updateOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER(
    $record: UpdateOneUserInput!
    $filter: FilterUpdateOneUserInput
    $sort: SortUpdateOneUserInput
    $skip: Int
  ) {
    updateUser(record: $record, filter: $filter, sort: $sort, skip: $skip) {
      record {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_CLIENT = gql`
  mutation UPDATE_CLIENT($data: UpdateClientInput) {
    updateClient(data: $data) {
      ...ClientFragment
    }
  }
  ${CLIENT_FRAGMENT}
`;

export const CREATE_PAYMENT = gql`
  mutation CREATE_PAYMENT($data: CreatePaymentInput) {
    createPayment(data: $data) {
      ...PaymentFragment
    }
  }
  ${PAYMENT_FRAGMENT}
`;

export const CREATE_PAYMENT_INTENT_STRIPE = gql`
  mutation CREATE_PAYMENT_INTENT_STRIPE($data: CreatePaymentIntentStripeInput) {
    createPaymentIntentStripe(data: $data) {
      appOrders {
        ...AppointmentOrderFragment
      }
      clientSecret
      payments {
        ...PaymentFragment
      }
    }
  }
  ${APPOINTMENT_ORDER_FRAGMENT}
  ${PAYMENT_FRAGMENT}
`;

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($data: ResetPasswordInput) {
    resetPassword(data: $data) {
      success
      err
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation CHANGE_PASSWORD($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      success
      err
    }
  }
`;

export const CREATE_APPOINTMENT = gql`
  mutation CREATE_APPOINTMENT($record: CreateOneAppointmentInput!) {
    createAppointment(record: $record) {
      recordId
      record {
        ...AppointmentFragment
      }
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const UPDATE_APPOINTMENT = gql`
  mutation UPDATE_APPOINTMENT(
    $record: UpdateOneAppointmentInput!
    $filter: FilterUpdateOneAppointmentInput
  ) {
    updateAppointment(record: $record, filter: $filter) {
      record {
        ...AppointmentFragment
      }
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const CREATE_APPOINTMENT_ORDER = gql`
  mutation CREATE_APPOINTMENT_ORDER(
    $data: CreateAppointmentOrderWithClientInput!
  ) {
    createAppointmentOrder(data: $data) {
      ...AppointmentOrderFragment
    }
  }

  ${APPOINTMENT_ORDER_FRAGMENT}
`;

export const UPDATE_APPOINTMENT_ORDER = gql`
  mutation UPDATE_APPOINTMENT_ORDER($data: UpdateAppointmentOrderInput!) {
    updateAppointmentOrder(data: $data) {
      ...AppointmentOrderFragment
    }
  }
  ${APPOINTMENT_ORDER_FRAGMENT}
`;

export const CREATE_SHOP_CART = gql`
  mutation CREATE_SHOP_CART($record: CreateOneShopCartInput!) {
    createShopCart(record: $record) {
      recordId
      record {
        ...ShopCartFragment
      }
    }
  }
  ${SHOP_CART_FRAGMENT}
`;

export const UPDATE_SHOP_CART = gql`
  mutation UPDATE_SHOP_CART(
    $record: UpdateOneShopCartInput!
    $filter: FilterUpdateOneShopCartInput
  ) {
    updateShopCart(record: $record, filter: $filter) {
      record {
        ...ShopCartFragment
      }
    }
  }
  ${SHOP_CART_FRAGMENT}
`;

export const UPDATE_PAYMENT = gql`
  mutation UPDATE_PAYMENT(
    $record: UpdateOnePaymentInput!
    $filter: FilterUpdateOnePaymentInput
  ) {
    updatePayment(record: $record, filter: $filter) {
      record {
        ...PaymentFragment
      }
    }
  }
  ${PAYMENT_FRAGMENT}
`;

export const SEND_APPOINTMENT_EMAIL_TO_CLIENT = gql`
  mutation SEND_APPOINTMENT_EMAIL_TO_CLIENT($data: SendAppointmentEmailInput!) {
    sendAppointmentEmailToClient(data: $data) {
      success
    }
  }
`;

export const SEND_APPOINTMENT_EMAIL = gql`
  mutation SEND_APPOINTMENT_EMAIL($data: SendAppointmentEmailInput!) {
    sendAppointmentEmail(data: $data) {
      success
    }
  }
`;

export const SEND_APPOINTMENT_EMAIL_TO_LAWYER = gql`
  mutation SEND_APPOINTMENT_EMAIL_TO_LAWYER($data: SendAppointmentEmailInput!) {
    sendAppointmentEmailToLawyer(data: $data) {
      success
    }
  }
`;

export const CREATE_USER = gql`
  mutation CREATE_USER($data: CreateUserInput!) {
    createUser(data: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const CREATE_CLIENT_USER = gql`
  mutation CREATE_CLIENT_USER($data: CreateClientUserInput!) {
    createClientUser(data: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_CLIENT_USER = gql`
  mutation UPDATE_CLIENT_USER($data: UpdateClientUserInput!) {
    updateClientUser(data: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const CREATE_CLIENT = gql`
  mutation CREATE_CLIENT($data: CreateClientInput!) {
    createClient(data: $data) {
      ...ClientFragment
    }
  }
  ${CLIENT_FRAGMENT}
`;

export const UPDATE_PAYMENT_STATUS = gql`
  mutation UPDATE_PAYMENT_STATUS($data: UpdatePaymentStatusInput!) {
    updatePaymentStatus(data: $data) {
      ...PaymentFragment
    }
  }
  ${PAYMENT_FRAGMENT}
`;

export const HANDLE_GOOGLE = gql`
  mutation HANDLE_GOOGLE($data: HandleGoogleInput!) {
    handleGoogle(data: $data) {
      token
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const ASSIGN_ORDER_PDF = gql`
  mutation ASSIGN_ORDER_PDF($data: GetFinalPdfInput!) {
    downloadOrderPdf(data: $data) {
      pdfUrl
      base64Pdf
    }
  }
`;
export const DOWNLOAD_SIGNNOW_DOCUMENT = gql`
  mutation DOWNLOAD_SIGNNOW_DOCUMENT($data: DownloadSignnowDocument) {
    downloadSignnowDocument(data: $data) {
      link
    }
  }
`;
