import React from 'react';

interface AnchorProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {}

export default function Anchor({
  href,
  target = '_blank',
  rel = 'noreferrer',
  className,
  children,
  ...props
}: AnchorProps) {
  return (
    <a href={href} target={target} rel={rel} className={className} {...props}>
      {children}
    </a>
  );
}
