import { InformationCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import Tooltip from './Tooltip';

interface LabelProps {
  label: string;
  description?: string;
}

export default function Label({ label, description }: LabelProps) {
  return (
    <div className="flex gap-2 items-center">
      <label className="text-sm font-medium text-gray-700">{label}</label>

      {description && (
        <Tooltip tooltip={description} className="w-52">
          <InformationCircleIcon className="h-5 w-5 text-gray-700" />
        </Tooltip>
      )}
    </div>
  );
}
