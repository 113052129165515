import React from 'react';
import { useCountry } from '../../../hooks';
import getCountryFlag from '../../../lib/getCountryFlag';
import { cn } from '../../../utils/cn';

interface CountrySelectorProps {
  onClick: () => void;
  className?: string;
}

export default function CountrySelector({
  onClick,
  className,
}: CountrySelectorProps) {
  const { currentCountry } = useCountry();

  return (
    <button
      type="button"
      onClick={onClick}
      className={cn('rounded-full p-2', className)}
    >
      {getCountryFlag(currentCountry, 'w-6 h-6')}
    </button>
  );
}
