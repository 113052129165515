import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '../../utils/cn';

interface SecondaryButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
}

export default function SecondaryButton({
  onClick,
  children,
  disabled,
  className,
}: SecondaryButtonProps) {
  return (
    <motion.button
      whileTap={{ scale: 0.95 }}
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={cn(
        'flex items-center justify-center gap-2 rounded-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm',
        className
      )}
    >
      {children}
    </motion.button>
  );
}
