/* eslint-disable no-nested-ternary */
import React from 'react';
import nProgress from 'nprogress';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { signIn } from 'next-auth/react';
import { EnvelopeIcon, KeyIcon } from '@heroicons/react/24/outline';
import { TailSpin } from 'react-loader-spinner';
import { validateEmail } from 'avilatek-utils';
import { SIGN_UP } from '../../graphql/mutation';
import { useNotify } from '../../hooks';
import GoogleIcon from '../icons/GoogleIcon';
import InputContainer from '../common/InputContainer';
import SecondaryButton from '../common/SecondaryButton';
import Anchor from '../common/Anchor';
import Checkbox from '../common/Checkbox';
import PrimaryButton from '../common/PrimaryButton';
import FormTitle from '../common/FormTitle';
import FormContainer from '../common/FormContainer';

interface RegisterFormProps {
  isModal?: boolean;
  setIsRegister?: React.Dispatch<React.SetStateAction<boolean>>;
}

function RegisterForm({ isModal = false, setIsRegister }: RegisterFormProps) {
  const router = useRouter();
  const notify = useNotify();
  const [signUp] = useMutation(SIGN_UP);

  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
  const [accepted, setAccepted] = React.useState(false);
  const [subscribed, setSubscribed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingGoogle, setLoadingGoogle] = React.useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);
      nProgress.start();
      if (!validateEmail(email))
        return notify('Debe ingresar un correo electrónico válido', 'error');
      if (password === '')
        return notify('Debe ingresar una contraseña', 'error');
      if (password !== passwordConfirmation)
        return notify('Las contraseñas no coinciden', 'error');
      if (!accepted)
        return notify(
          'Debe aceptar los términos y condiciones para continuar',
          'error'
        );

      const { data } = await signUp({
        variables: {
          data: {
            email,
            password,
            subscribed,
          },
        },
      });
      if (data?.signUp) {
        onSignIn();
      } else {
        notify('Ha ocurrido un error', 'error');
      }
    } catch (err) {
      console.log(err.message);
      notify(err.message, 'error');
    } finally {
      nProgress.done();
      setLoading(false);
    }
  };

  const onGoogle = async () => {
    nProgress.start();
    setLoadingGoogle(true);
    if (!accepted) {
      notify('Debe aceptar los terminos y condiciones para continuar', 'error');
      nProgress.done();
      setLoadingGoogle(false);
      return;
    } // Mostrar el spinner
    signIn('google', {
      callbackUrl: `/`,
    })
      .then(() => {
        notify('¡Registro exitoso! Espere unos segundos', 'success');
      })
      .catch((err) => {
        console.log('err', err);
        notify('An unexpected error occurred: ' + err.message, 'error');
      })
      .finally(() => {
        setLoadingGoogle(false); // Ocultar el spinner
        nProgress.done();
      });
  };

  const onSignIn = () => {
    setLoading(true); // Mostrar el spinner
    nProgress.start();
    signIn('credentials', {
      email,
      password,
      redirect: false,
    })
      .then((result) => {
        if (result.error) notify(result.error, 'error');
        else notify('Registro exitoso.', 'success');
      })
      .catch((err) => {
        console.log('err', err);
        notify('Ocurrió un error inesperado: ' + err.message, 'error');
      })
      .finally(() => {
        setLoading(false); // Ocultar el spinner
        nProgress.done();
      });
  };

  return (
    <FormContainer isModal={isModal}>
      <FormTitle
        title="Crea una nueva cuenta"
        description="Ingresa tus datos para crear una cuenta"
      />

      <SecondaryButton
        disabled={loadingGoogle || loading}
        className="font-semibold shadow"
        onClick={async (e) => {
          e.preventDefault();
          await onGoogle();
        }}
      >
        <span className="mr-2">
          <GoogleIcon className="h-4 w-4" />
        </span>
        <p>Continúa con Google</p>
      </SecondaryButton>
      <div className="w-full h-fit justify-center items-center gap-2 flex">
        <div className="grow shrink border-t border-gray-300 border-dashed" />
        <div className="text-gray-500 text-sm font-medium  leading-normal">
          O
        </div>
        <div className="grow shrink border-t border-gray-300 border-dashed" />
      </div>
      <InputContainer
        label="Correo electrónico"
        type="email"
        placeholder="Ingresa tu correo"
        leading={<EnvelopeIcon className="w-5 h-5 shrink-0" />}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <InputContainer
        label="Contraseña"
        type="password"
        value={password}
        placeholder="Ingresa tu contraseña"
        onChange={(e) => setPassword(e.target.value)}
        leading={<KeyIcon className="w-5 h-5 shrink-0" />}
      />
      <InputContainer
        label="Confirmar Contraseña"
        type="password"
        value={passwordConfirmation}
        onChange={(e) => setPasswordConfirmation(e.target.value)}
        placeholder="Confirma tu contraseña"
        leading={<KeyIcon className="w-5 h-5 shrink-0" />}
      />
      <div className="flex flex-col text-gray-500 text-sm gap-3">
        <div className="flex items-center gap-4">
          <Checkbox onChange={() => setSubscribed(!subscribed)} />
          <label>Suscribirme al boletín informativo</label>
        </div>
        <div className="flex items-start gap-4">
          <Checkbox onChange={() => setAccepted(!accepted)} />
          <div className="text-gray-500">
            Acepto los{' '}
            <Anchor
              href="https://widulegal.com/ve/terminos-y-condiciones"
              className="text-primary-600 font-semibold"
            >
              Términos y Condiciones
            </Anchor>
            , y{' '}
            <Anchor
              href="https://widulegal.com/ve/politica-de-privacidad"
              className="text-primary-600 font-semibold"
            >
              Políticas de privacidad
            </Anchor>
          </div>
        </div>
      </div>

      <PrimaryButton
        disabled={loadingGoogle || loading}
        className="font-semibold shadow"
        onClick={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        {loading ? (
          <TailSpin height={20} width={20} color="white" />
        ) : (
          <p>Registrarme</p>
        )}
      </PrimaryButton>
      <div className="flex flex-col items-center justify-center gap-x-1 md:flex-row md:gap-x-2">
        <p className="font-text text-sm font-medium text-gray-500">
          ¿Ya tienes cuenta?
        </p>
        <button
          className="w-fit md:w-auto font-text text-sm font-semibold text-primary-600"
          type="button"
          disabled={loadingGoogle || loading}
          onClick={
            isModal ? () => setIsRegister(false) : () => router?.push('/login')
          }
        >
          Iniciar sesión
        </button>
      </div>
    </FormContainer>
  );
}

export default RegisterForm;
