import React from 'react';
import { cn } from '../../utils/cn';

interface CheckboxProps {
  onChange: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

export default function Checkbox({ onChange, className }: CheckboxProps) {
  return (
    <input
      type="checkbox"
      onChange={onChange}
      className={cn('rounded border-gray-300', className)}
    />
  );
}
