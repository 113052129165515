import React from 'react';

interface FormTitleProps {
  title: string;
  description?: string;
}

export default function FormTitle({ title, description }: FormTitleProps) {
  return (
    <div className="flex flex-col gap-0.5">
      <h3 className="text-gray-900 font-semibold text-2xl">{title}</h3>
      <p className="text-gray-600 text-sm">{description}</p>
    </div>
  );
}
